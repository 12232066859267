export const bank = {
  AUTH: 'auth.emiratesislamic.ae',
  HOST: "openapi.emiratesislamic.ae",
  ACCOUNT_NUMBER: "3708478484801",
  
  FINANCIAL_ID: "EIB",
  CHANNEL_ID: "OBP",
  TRANSFER_API: '/open-banking/payment/v2/transfers',
  TOKEN_API: '/token',
  IBAN: "AE660340003708478484801",
  
  AUTH_UAT: 'authuat.emiratesislamic.ae',
  HOST_UAT: "openapiuat.emiratesislamic.ae",
  ACCOUNT_NUMBER_UAT: "3707232489901", //"1011071936906","3707382646401"
}